import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Link from "@mui/material/Link"
import { useStaticQuery, graphql } from "gatsby"

const TermsOfUse = () => {
  const data = useStaticQuery(graphql`
    query TCQuery {
      site {
        siteMetadata {
          social {
            email
          }
        }
      }
    }
  `)

  const email = data.site.siteMetadata.social?.email

  return (
    <>
      <Layout>
        <Seo
          title="Terms of use"
          description="Terms of use for loanprepayplanner.com"
        />

        <div>
          <h2>
            <strong>Terms &amp; Conditions</strong>
          </h2>
          <p>
            By using the Service, these terms will automatically apply to you -
            you should make sure therefore that you read them carefully before
            using the service. You're not allowed to copy or modify the service,
            any part of the service, or our trademarks in any way. You're not
            allowed to attempt to extract the source code of the service, and
            you also shouldn't try to translate the service into other languages
            or make derivative versions. The service itself, and all the
            trademarks, copyright, database rights, and other intellectual
            property rights related to it, still belong to us.
          </p>
          <p>
            We are committed to ensure that the service is as useful and
            efficient as possible. For that reason, we reserve the right to make
            changes to the service or to charge for its services, at any time
            and for any reason. We will never charge you for the service or its
            services without making it very clear to you exactly what you're
            paying for.
          </p>
          <p>
            Link to Terms and Conditions of third-party service providers used
            by the service
          </p>
          <ul>
            <li>
              <Link
                target="_blank"
                href="https://firebase.google.com/terms/analytics"
                underline="hover"
              >
                Google Analytics terms of service
              </Link>
            </li>
          </ul>
          <p>
            You should be aware that there are certain things that we will not
            take responsibility for. Certain functions of the service will
            require you to have an active internet connection. The connection
            can be Wi-Fi or provided by your mobile network provider, but we
            cannot take responsibility for the service not working at full
            functionality if you don't have access to Wi-Fi, and you don't have
            any of your data allowance left.
          </p>
          <p>
            With respect to our responsibility for your use of the service, when
            you're using the service, it's important to bear in mind that
            although we endeavor to ensure that it is updated and correct at all
            times, we do rely on third parties to provide information to us so
            that we can make it available to you. We accept no liability for any
            loss, direct or indirect, you experience as a result of relying
            wholly on this functionality of the service.
          </p>
          <h3>
            <strong>Changes to This Terms and Conditions</strong>
          </h3>
          <p>
            We may update our Terms and Conditions from time to time. Thus, you
            are advised to review this page periodically for any changes. We
            will notify you of any changes by posting the new Terms and
            Conditions on this page.
          </p>
          <p>These terms and conditions are effective as of April 01, 2022</p>
          <h4>
            <strong>Contact Us</strong>
          </h4>
          <p>
            If you have any questions or suggestions about Terms and Conditions,
            do not hesitate to contact us at{" "}
            <Link target="_blank" href={`mailto:${email}`} underline="hover">
              {email}
            </Link>
            .
          </p>
        </div>
      </Layout>
    </>
  )
}

export default TermsOfUse
